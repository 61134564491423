.image-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust the minmax values as per your preference */
  grid-gap: 10px; /* Space between grid items */
  padding: 10px;
}

.image-thumbnail {
  cursor: pointer;
}

.image-thumb {
  width: 100%; /* Full width of the grid column */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
}

.image-caption {
  text-align: left; /* Center-align the caption */
  margin-top: 5px; /* Space between image and caption */
  color: black; /* Caption text color */
  font-size: 12px; /* Caption font size */
  font-weight: bold;
  /* Add any other styling you prefer */
}