/* Base font setting for the whole app */
body {
  background-color: ghostwhite;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* Global header style */
header {
  display: flex;
  justify-content: left;
  margin-left: 200px;
  align-items: left;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: ghostwhite;
  padding-left: 50px;

  /* Header font styles */
  font-family: inherit;
  font-weight: bold; /* Makes the font bold */
  font-size: 36px; /* Default font size */
  color: black; /* Dark gray font color */
  line-height: 100px; /* Adjust line height */
  letter-spacing: 1px; /* Space between letters */
}

.logo-background {
  background-image: url('./assets/VilanoLogo.PNG'); /* Adjust path as needed */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

/*Grid buttons*/
.main-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    grid-gap: 10px; /* Gap between grid items */
    padding: 20px; /* Padding around the grid */
}

.main-content-button {
    display: block;
    width: 90%;
    padding: 10px;
    margin: 5px auto;
    background-color: cornflowerblue;
    color: white;
    border: none;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
}

.main-content-button:hover {
    background-color: #45a049;
}


/* General Styles */
.paragraph {
  font-size: smaller;
  color: black;
  font-family: inherit; /* Inherits font from body */
}

/* Header Styles */
.logo-and-start {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
}

.header-logo {
  font-size: 72px;
  color: ghostwhite;
  font-family: inherit; /* Inherits font from body */
  margin-bottom: 20px; /* Creates space between the image and the button */
  margin-left: 40px; /* Creates space between the image and the button */
}

.email-input {
  /* Style your email input field here */
  margin-right: 10px; /* Space between the input field and the button */
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.home-header, .other-page-header {
  height: 100px;
  background-color: transparent; /* Make these headers also transparent */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: inherit; /* Inherits font from body */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Main Content Styles */

.main-content {
  margin-left: 200px;
  margin-top: 100px;
  /* Other main content styles */
}

/* Image Styles */
.image-fullscreen, .image-responsive {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

/* Form Container Styles */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center-top; /* Center vertically */
  padding-top: 20px;
  align-items: center; /* Center horizontally */
  width: 100%; /* Full width of the container */
  height: 100vh; /* Full viewport height if it's a standalone page */
  /* Or use a specific height if it's within another element */
}

/* Input Field Styles */
.login-container input[type="email"], .login-container input[type="password"] {
  width: 30%; /* Consistent width for both fields */
  padding: 5px;
  margin: 5px 0; /* Spacing between text fields */
  font: inherit;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* Add other styles as needed */
}

.image-fullscreen, .image-responsive {
max-width: 100%;
height: auto;
}

.HomePage, .DashboardPage, .ProjectsPage, .DeficienciesPage {
background-position: top center;
}

/* Page Specific Styles */
.HomePage, .DashboardPage, .ProjectsPage, .DeficienciesPage {
text-align: center;
background-size: cover;
background-position: top right;
background-repeat: no-repeat;
min-height: 1024px;
}

.HomePage {
background-color: black;
background-image: url('./assets/VilanoLogo.PNG');
}

.DashboardPage, .ProjectsPage, .Project1Page, .Project2Page, .Project3Page, .DeficienciesPage {
background-color: ghostwhite;
color: black; /* Ensures text color is black */
text-align: left;
margin-left: 50px;
}

/* Top Left and Right Container Styles */
.top-left, .top-right {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  padding: 10px;
}

.top-left {
  left: 0;
}

.top-right {
  right: 0;
}

/* Button Styles */
.register-button, .login-button {
  border: 0;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 250ms, color 250ms;
}

.register-button {
  color: rgb(255,255,255); /* White text color */
  background: rgb(229,9,20); /* Red background */
}

.login-button {
  color: rgb(255,255,255); /* White text color */
  background: #45a049; /* Green background */
}

.register-button:hover, .login-button:hover {
  background-color: #e60017; /* Darker shade of red on hover */
  color: black;
}

/* Link Styles */
.top-right a {
  text-decoration: none; /* Removes underline from the link */
  color: inherit; /* Inherits color from parent */
}

/* Role Selector Button Styles */
.register-container .role-selector-button {
  background-color: #ccc; /* Neutral background for unselected */
  color: black;
  padding: 5px 10px; /* Padding around text */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  /* Center the button */
  display: block;
  margin: 5px auto; /* Vertical margin with auto horizontal margin for centering */
}

/* Apply the 'selected' style when a role is selected */
.register-container .role-selector-button.selected {
  background-color: #4CAF50; /* Green background for selected role */
  color: white;
}

.register-container input,
.register-container button {
  margin: 5px 0; /* Spacing between form elements */
  max-width: 50%; /* Maximum width */
  /* Center the elements */
  display: block;
  margin-left: auto;
  margin-right: auto;
  font: inherit;
  padding: 5px;
  border-radius: 5px;
}

.error-message {
  color: red; /* Style for error messages */
  text-align: center; /* Center the text */
  width: 100%; /* Full width */
  display: block; /* Block-level for proper text alignment */
  margin-top: 10px; /* Spacing above the message */
}

.role-selection-label {
  margin-bottom: 5px; /* Space before the buttons */
  font-weight: bold;
  text-align: center; /* Center the label text */
}


.confirm-button {
  background-color: #4CAF50; /* Green background for selected role */
  color: black;
  /* Removed width property to avoid conflict */
}
