/* Styles for screens smaller than 1200px */
@media (max-width: 1200px) {
  /* Add specific styles for this breakpoint */
}

/* Styles for screens smaller than 992px */
@media (max-width: 992px) {
  /* Add specific styles for this breakpoint */
}

/* Styles for screens smaller than 768px */
@media (max-width: 768px) {
  header {
    font-size: 24px; /* Even smaller font size for small screens */
    margin-left: 100px; /* Adjust margin */
    padding-left: 20px; /* Adjust padding */
  }

  .header-buttons,
  .sidebar,
  .header-content {
    justify-content: space-around; /* For .header-buttons */
    width: 100%; /* Full width for .sidebar and .header-content */
    flex-direction: column; /* For .header-content */
  }

  .main-content {
    margin-left: 0;
  }

  .header-logo {
    font-size: 48px; /* Smaller font size for mobile */
    margin-left: 20px;
  }

  .email-input,
  .header-buttons,
  .form-container,
  .input-field {
    width: 90%; /* Full width for smaller screens */
  }

  .input[type="text"], .input[type="password"] {
    font-size: 16px; /* Larger font size for better readability */
  }

  /* Adjusted styles for .right-sidebar */
  .right-sidebar {
    /* Add specific styles for this breakpoint */
  }
}

/* Styles for screens smaller than 480px */
@media (max-width: 480px) {
  header {
    font-size: 18px; /* Smaller font size for very small screens */
    margin-left: 50px; /* Adjust margin */
    padding-left: 10px; /* Adjust padding */
  }

  /* Adjusted styles for .right-sidebar */
  .right-sidebar {
    /* Add specific styles for this breakpoint */
  }
}

/* Styles for screens smaller than 1024px */
@media (max-width: 1024px) {
  header {
    font-size: 30px; /* Smaller font size for medium screens */
  }
}
