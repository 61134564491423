/* Sidebar and Main Content Styles */
.sidebar {
    width: 200px;
    transition: width 0.3s; /* Smooth transition for sidebar width */
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    box-sizing: border-box;
    overflow-y: auto; /* Allows scrolling if content overflows */
}

.sidebar-logo {
    width: 200px; /* Slightly less than the sidebar width to accommodate padding/margins */
    height: 200px; /* Maintain aspect ratio */
    display: block; /* Ensure the image is block-level */
    object-fit: cover; /* Adjust how the image fits into the box */
}

.sidebar-button {
    display: block; /* Makes each button a block-level element */
    width: 90%; /* Each button takes up 90% of the sidebar's width */
    padding: 10px; /* Adds padding inside the buttons */
    margin: 5px auto; /* Centers button with 5px margin at the top and bottom */
    background-color: cornflowerblue;
    color: white;
    border: none;
    text-align: center;
    cursor: pointer;
    border-radius: 5px; /* For rounded corners */
    box-sizing: border-box; /* Include padding in width calculation */
}

.sidebar-button:hover {
    background-color: #45a049;
}

.sidebar-signout {
    color: white;
    background-color: red;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: block; /* Ensure block-level element */
    font-size: 16px;
    margin: 4px auto; /* Center the button with top and bottom margin */
    cursor: pointer;
    width: 90%; /* Button takes up 90% of the sidebar's width */
    box-sizing: border-box; /* Include padding and border in width calculation */
    border-radius: 5px;
    position: absolute;
    bottom: 10px; /* Position at the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Offset by half of its width for perfect centering */
}


/* Responsive Adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 50px; /* Collapsed width */
    }

    .sidebar-logo {
        width: 40px; /* Adjust the logo size to fit the collapsed sidebar */
        height: auto; /* Maintain aspect ratio */
    }

    .main-content {
        margin-left: 50px; /* Adjust according to the collapsed sidebar */
    }

    .sidebar-button, .sidebar-signout {
        padding: 5px 10px; /* Reduce padding for a more compact look */
        font-size: 12px; /* Optionally reduce font size for button text */
    }
}
